import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function ContactPage() {
  return (
    <Layout>
      <SEO
        keywords={[`contato`, `formulario`, `form`]}
        title="Contact"
      />
      <section>

        <div className="md:flex">


          <div className="md:w-1/2 p-4">
            <form
              action="https://formspree.io/xknjwlag"
              method="POST"
              >

              <label
                className="block mb-2"
                htmlFor="name"
                >
                Nome
              </label>

              <input
                className="appearance-none block bg-gray-200 mb-6 px-3 py-2 rounded-md text-gray-700 w-full"
                name="name"
                placeholder="Nome"
                type="text"
                />

              <label
                className="block mb-2"
                htmlFor="email"
                >
                e-mail
              </label>

              <input
                className="appearance-none block bg-gray-200 mb-6 px-3 py-2 rounded-md text-gray-700 w-full"
                name="email"
                placeholder="email"
                type="text"
                />

              <input type="text" name="_gotcha" style={{"display":"none"}} />

              <label
                className="block mb-2"
                htmlFor="message"
                >
                O que podemos fazer por você?
              </label>

              <textarea
                className="appearance-none bg-gray-200 mb-6 px-3 py-2 rounded-md text-gray-700 w-full"
                name="message"
                placeholder="Mensagem..."
                rows="8"
                />

              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Enviar
              </button>
            </form>
          </div>

          <div className="md:w-1/2 p-4">
            <div>
              <svg
                className="object-center object-scale-down float-left pr-2 sm:w-24 sm:h-24 w-16 h-16 "
                viewBox="-39 0 411 411.222"
                >
                <path d="M108.64 258.477c-16.078-17.125-27.886-35.832-35.1-55.598a150.738 150.738 0 01-9.126-62.031 160.625 160.625 0 0146.367-102.715A151.222 151.222 0 01137.2 16.957a148.984 148.984 0 00-76.418 41.176 160.625 160.625 0 00-46.367 102.715 150.729 150.729 0 009.125 62.027c7.215 19.77 19.023 38.477 35.102 55.602L167.094 394l34.39-36.629zm0 0" fill="#d4e1f4"/><path d="M167.094 277.313c-63.918 0-115.922-53.317-115.922-118.848S103.176 39.613 167.094 39.613c63.922 0 115.922 53.313 115.922 118.848 0 65.531-52 118.851-115.922 118.851zm0-223.7c-56.2 0-101.922 47.035-101.922 104.848s45.723 104.851 101.922 104.851c56.203 0 101.922-47.039 101.922-104.851S223.296 53.613 167.094 53.613zm0 0" fill="#54d3ce"/><g fill="#0635c9"><path d="M48.434 288.059l113.562 120.953c1.32 1.41 3.168 2.21 5.102 2.21s3.78-.8 5.105-2.21l113.555-120.953c17.379-18.512 30.18-38.829 38.043-60.383a164.634 164.634 0 009.941-67.762A174.598 174.598 0 00283.324 48.25 164.11 164.11 0 00167.094 0 164.107 164.107 0 0050.867 48.25 174.623 174.623 0 00.445 159.914a164.634 164.634 0 009.942 67.762c7.867 21.554 20.668 41.87 38.047 60.383zm-34.02-127.211A160.625 160.625 0 0160.781 58.133 150.122 150.122 0 01167.094 14a150.109 150.109 0 01106.312 44.133 160.605 160.605 0 0146.367 102.715 150.664 150.664 0 01-9.125 62.027c-7.21 19.77-19.02 38.477-35.097 55.602L167.094 394 58.64 278.477c-16.078-17.125-27.887-35.832-35.102-55.598a150.747 150.747 0 01-9.125-62.031zm0 0"/><path d="M148.34 193.883a6.996 6.996 0 009.781.113l63.809-60.945a7 7 0 00.226-9.899 6.995 6.995 0 00-9.894-.226l-58.86 56.219-31.355-31.356a7.002 7.002 0 00-9.902 9.902zm0 0"/></g>
              </svg>
            </div>
            <div>
              <a href="https://goo.gl/maps/1VBw7ArVfvH2" title="maps" target="_blank">
                <p>Rua Clélia, 550 - Cj. 96</p>
                <p>São Paulo - Brasil</p>
                <p>05042-000</p>
                <p><a href="mailto:contato@infranology.com.br">contato@infranology.com.br</a></p>
              </a>
            </div>

            <div className="w-full py-4">
              <iframe
                height="450"
                style={{ border: "0", width: "100%" }}
                allowfullscreen
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.212897362326!2d-46.69005248452682!3d-23.524843966130994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cef80b732200db%3A0x255b85f494abdbc4!2sR.+Cl%C3%A9lia%2C+550+-+Barra+Funda%2C+S%C3%A3o+Paulo+-+SP%2C+05027-000!5e0!3m2!1sen!2sbr!4v1550026820012"
                >
              </iframe>
            </div>

          </div>

        </div>

      </section>
    </Layout>
  );
}

export default ContactPage;
